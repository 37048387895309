import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { worldOctree } from './script';

export const modelBoundingBoxes = [];

export const loadModelRigidos = (scene, loadingManager) => {
    return new Promise((resolve, reject) => {
        const loader = new GLTFLoader(loadingManager);
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        loader.setDRACOLoader(dracoLoader);

        loader.load(
            "static/models/caminar-rigidos.glb",
            (gltf) => {
                const cRigidos = gltf.scene;
                cRigidos.position.set(-2, 3, 5);
                cRigidos.scale.set(1, 1, 1);  // Ajustar la escala
                cRigidos.rotation.y = Math.PI / 2;

                // Recorrer todos los nodos, incluyendo Group y Mesh
                cRigidos.traverse((node) => {
                    if (node.isMesh) {
                        // Configurar sombras para nodos de tipo Mesh
                        node.castShadow = true;
                        node.receiveShadow = true;

                        // Configuración de los materiales
                        const materials = Array.isArray(node.material) ? node.material : [node.material];
                        materials.forEach((material) => {
                            if (material.map) {
                                material.map.encoding = THREE.sRGBEncoding;
                            }
                            material.roughness = 0.5;
                            material.metalness = 0.5; // sombras internas resaltan
                        });

                        // Agregar la malla al Octree
                        worldOctree.fromGraphNode(node);

                        // Opcional: Agregar a bounding boxes (si es necesario)
                        const boundingBox = new THREE.Box3().setFromObject(node);
                        modelBoundingBoxes.push(boundingBox);
                    }

                    if (node.isGroup) {
                        // Recorremos los hijos del grupo para asegurarnos de que también se apliquen sombras
                        node.children.forEach((child) => {
                            if (child.isMesh) {
                                child.castShadow = true;
                                child.receiveShadow = true;
                            }
                        });
                    }
                });

                resolve(cRigidos);
                scene.add(cRigidos);  // Añadir a la escena solo aquí, una vez cargado
            },
            undefined,
            (error) => {
                reject(error);
                console.error("Error al cargar el modelo caminar-rigidos.gltf", error);
            }
        );
    });
};
