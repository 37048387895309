import * as THREE from 'three';

export const paintingData = [];

// Clase que representa cada pintura con su información y posición
class PaintingData {
    constructor(info) {
        this.info = info;
        this.nodePosition = null; // La posición se asignará desde modelsObras.js
    }

    // Asignar la posición a la pintura
    setPosition(position) {
        this.nodePosition = position;
    }
}

// Crear las pinturas con su información y audio
paintingData.push(
    
    // magritte
    new PaintingData(
        { title: "Somos individuos multi-tarea? O mas bien la ansiedad domina nuestras vidas y nos impulsa a exigrnos al límite? Valoramos nuestro tiempo? Somos concientes de en qué lo invertimos?", description: "Para Byung-Chul Han la autoexplotación se ha vuelto la norma. Según Han, vivimos en una época en la que el individuo ya no necesita un jefe o un sistema represivo externo para ser productivo; él mismo se convierte en su propio explotador.", song: "Loscil - Sickboy", audioFile: "../static/audio/descr1.mp3" }
    ),
    
    // escher ojo
    new PaintingData(
        { title: "Qué es la muerte? Es un fin? Cuál es nuestra posición frente a ella? Nos preocupa? ¿Qué legado dejaré tras de mí? Cómo te gustaría morir?", description: "Memento mori -Recuerda que vas a morir-. La propia muerte es una de las pocas certezas que tenemos. Reflexionar sobre ella potencia la vida y la hace más valiosa.", song: "Ji Bark - Sad Amore", audioFile: "../static/audio/descr2.mp3" }
    ),

    // banksy
    new PaintingData(
        { title: "Qué estamos dispuestos a hacer por el crecimiento económico? Es el dinero nuestra principal preocupación? Ejercemos o padecemos la violencia del poder?", description: "El artista callejero Banksy, nos invita a reflexionar sobre la autoridad, el control y otras formas de poder que nos oprimien a lo largo de nuestra vida.", song: "Max Richter- The Trees", audioFile: "../static/audio/descr3.mp3" }
    ),

    // el toro
    new PaintingData(
        { title: "Qué es esencial, que es accesorio? Puedo simplificar mi vida para enfocarla en aquellas cosas que juzgo esenciales? Qué es esencial para mi? Qué cosas me definen como individuo? Qué características me hacen una persona diferente a las demás? ", description: "Detectar lo esencial nos permite enfocar y desarrollar lo valioso y genuino de nuestra vida. Menos es mas, quitemos lo que sobra. ", song: "Aphex Twin - Stone in focus", audioFile: "../static/audio/descr4.mp3" }
    ),

    // 5 pollock
    new PaintingData(
        { title: "Podemos trabajar de otra forma para asi obtener resultados diferentes? Nos atrevemos a innovar, a hacer cosas distintas? Solemos expresar nuestros sentimientos en forma abierta?", description: "Pollock trabajaba desde dentro del cuadro para expresar sus emociones. El arte puede ser un gran medio para comunicar y expresar nuestros sentimientos, una herramienta, un ejercicio liberador.", song: "Eluvium - Prelude For Time Feelers", audioFile: "../static/audio/descr5.mp3" }
    ),

    // 6 sound wave
    new PaintingData(
        { title: "La tecnología impone cambios cada vez más vertiginosos. Cómo impacta en nosotros esta ola de cambios? Podemos beneficiarnos o nos vuelven cada vez más obsoletos? Cómo influye en nuestra forma de pensar?", description: "El arte contemporáneo rompe el marco y expande la creatividad hacia objetos, performances e instalaciones. En este caso, nos invita a pensar cómo la obsolescencia en tecnología impulsa un cambio radical que impacta en nuestra vida.", song: "Cylob - Tryachus", audioFile: "../static/audio/descr6.mp3"  }
    ),


    // 7 dali
    new PaintingData(
        { title: "Qué tanto nos pesa la mirada del otro? Cómo influye en mi la mirada de los demás? Tengo mi propia forma de ver? Que pasaría si vemos nuestros problemas desde otro ángulo?", description: "Velazquez nos invita a pensar sobre el ver y el ser visto, pero tambien sobre nuestro importante papel dentro del arte. Rompe con el concepto de un espectador pasivo y lo transforma en parte integral de la escena. El espectador completa la obra con su presencia y su interpretación.", song: "Freescha - Holiday Frost", audioFile: "../static/audio/descr7.mp3" }
    ),


    // 9 archimboldo
    new PaintingData(
        { title: "Existen otras perspectivas posibles? Puedo ver mi vida con ojos renovados? Me atrevo a hacerlo? De qué manera? Confio en mi capacidad creativa?", description: "El arte y la obra abierta son los refugios de la libertad. A través de la interpretación somos libres para recrear o cocrear la obra.", song: "Ji Bark - Piano Concerto", audioFile: "../static/audio/descr9.mp3" }
    ),

    
    // 8 
    new PaintingData(
        { title: "Es tan equilibrado el mundo o en la dualidad siempre se hay hegemonías? Todo es dual? Es necesario que haya maldad? Quien establece el equilibrio?", description: "Escher pinta un orden universal. Formado por aquellas dualidades que experimentamos a diario: lo claro y lo oscuro, el bien y el mal, etc. No son figuras separadas, se moldean a partir del mismo espacio, cada una definiendo a la otra y compartiendo sus límites.", song: "Deaf Center - Life Cycle", audioFile: "../static/audio/descr8.mp3" }
    )  

);
