import * as THREE from 'three';
import { paintingData } from './paintingData.js';
import gsap from "gsap";  // Importar GSAP para animaciones


export const paintingInfo = (paintings, camera) => {
    const distanceThreshold = 2.5; // Umbral de distancia para mostrar popup
    let paintingToShow = null;

    // Verificamos si hay alguna pintura cercana
    paintingData.forEach((painting) => {
        if (!painting.nodePosition) return;  // Asegurarse de que hay una posición asignada

        const distanceToPainting = camera.position.distanceTo(painting.nodePosition);

     //   console.log(`Distancia a la pintura ${painting.info.title}: ${distanceToPainting}`);

        if (distanceToPainting < distanceThreshold) {
            paintingToShow = painting; // Si está cerca, marcar la pintura
        }
    });

    // Solo mostrar el popup si hay una pintura cercana
    if (paintingToShow) {
        // console.log(`Mostrando popup para la pintura: ${paintingToShow.info.title}`);
        displayInfo(paintingToShow.info);
    } else {
       // console.log('No hay pinturas cercanas, ocultando popup.');
        hideInfo();  // Solo ocultar el popup si no estás cerca de ninguna pintura
    }
};

// seccion de audios descrpitivos -----------------------------------

let audioContext;
let audioBuffer;
let audioSource;
let isPlaying = false;

// Nueva variable para almacenar la URL actual del audio
let currentAudioUrl = null;

// Función para cargar y reproducir el audio usando Web Audio API
const playAudio = async (audioUrl, button) => {
    if (!audioContext) {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
    }

    // Si el audio está en reproducción y el nuevo archivo es diferente, detener el actual
    if (isPlaying && currentAudioUrl !== audioUrl) {
        audioSource.stop();
        isPlaying = false;
        currentAudioUrl = null;
        button.innerHTML = '<i class="fas fa-play" style="color: #40ff92; font-size: 1.2em; margin-right: 8px;"></i> <span style="color: #40ff92;">Reproducir Descripción</span>';
    }

    // Si el nuevo audio es el mismo, solo pausar o reproducir
    if (isPlaying && currentAudioUrl === audioUrl) {
        audioSource.stop();
        isPlaying = false;
        button.innerHTML = '<i class="fas fa-play" style="color: #40ff92; font-size: 1.2em; margin-right: 8px;"></i> <span style="color: #40ff92;">Reproducir Descripción</span>';
        return;
    }

    try {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Crear una nueva fuente de audio
        audioSource = audioContext.createBufferSource();
        audioSource.buffer = audioBuffer;
        audioSource.connect(audioContext.destination);
        audioSource.start(0);
        isPlaying = true;

        // Actualizar la URL del audio que se está reproduciendo
        currentAudioUrl = audioUrl;

        // Cambiar el texto y el icono a "Pausar Descripción"
        button.innerHTML = '<i class="fas fa-pause" style="color: #ff6178;  font-size: 1.2em; margin-right: 8px;"></i> <span style="color: #ff6178;">Pausar Descripción</span>';

        audioSource.onended = () => {
            isPlaying = false;
            currentAudioUrl = null;  // Resetear la URL actual
            button.innerHTML = '<i class="fas fa-play" style="color: #40ff92; font-size: 1.2em; margin-right: 8px;"></i> <span style="color: #40ff92;">Reproducir Descripción</span>';
        };
    } catch (error) {
        console.error('Error al cargar o reproducir el audio:', error);
    }
};

// Modificar la función displayInfo
const displayInfo = (info) => {
    const popupElement = document.getElementById('painting-popup');
    const popupTitle = document.getElementById('popup-title');
    const popupSong = document.getElementById('popup-song');
    const popupDescription = document.getElementById('popup-description');

    // Actualizar el contenido del popup
    popupTitle.textContent = info.title;
    popupDescription.textContent = info.description;
    popupSong.textContent = info.song;

    // Limpiar el popup antes de añadir el botón (evitar duplicación)
    const existingButton = document.getElementById('play-button');
    if (!existingButton) {
        // Añadir un botón para reproducir el audio
        const playButton = document.createElement('button');
        playButton.id = 'play-button'; 
        playButton.innerHTML = '<i class="fas fa-play" style="color: #40ff92; font-size: 1.2em; margin-right: 8px;"></i> <span style="color: #40ff92;">Reproducir Descripción</span>';
        playButton.onclick = () => playAudio(info.audioFile, playButton); // Pasar botón como argumento
        
        // Insertar el botón justo después del popupDescription
        popupDescription.insertAdjacentElement('afterend', playButton);
    } else {
        // Si el botón ya existe, actualizamos el evento onClick para la nueva pintura
        existingButton.onclick = () => playAudio(info.audioFile, existingButton);
    }

    // Mostrar el popup
    gsap.to(popupElement, {
        opacity: 1,
        duration: 0.5,
        display: 'block'
    });
};



// Modificar la función hideInfo para detener el audio
const hideInfo = () => {
    const popupElement = document.getElementById('painting-popup');
    
    // Detener el audio cuando se cierra el popup
    if (audioSource) {
        audioSource.stop();
        isPlaying = false;
        currentAudioUrl = null;  // Resetear la URL actual
        
        // Restablecer el texto del botón al cerrar el popup
        const playButton = document.getElementById('play-button');
        if (playButton) {
            playButton.innerHTML = '<i class="fas fa-play" style="color: #40ff92; font-size: 1.2em; margin-right: 8px;"></i> <span style="color: #40ff92;">Reproducir Descripción</span>';
        }
    }

    // Animar la desaparición del popup
    gsap.to(popupElement, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
            popupElement.style.display = 'none';
        }
    });
};
