import './style.css';
import * as THREE from 'three';
import { camera, scene } from './script';

let listener;

function init() {
  listener = new THREE.AudioListener();
  camera.add(listener);

  const framesData = [
    { position: [-11, 4, 3.6], rotationY: Math.PI / 2, audioId: 'obra1' },
    { position: [-11, 4, -10], rotationY: Math.PI / 2, audioId: 'obra2' },
    { position: [-13.5, 9.5, 4], rotationY: Math.PI / 2, audioId: 'obra3' },
    { position: [-13.5, 9.5, -9.5], rotationY: Math.PI / 2, audioId: 'obra4' },
    { position: [-21, 10, -3.5], rotationY: Math.PI / 2, audioId: 'obra5' },
    { position: [12, 4, 1.8], rotationY: Math.PI / 2, audioId: 'obra6' },
    { position: [12, 4, -10], rotationY: Math.PI / 2, audioId: 'obra7' },
    { position: [14, 10, -10], rotationY: Math.PI / 2, audioId: 'obra8' },
    { position: [14, 10, 4.5], rotationY: Math.PI / 2, audioId: 'obra9' },
  ];

  framesData.forEach(({ position, rotationY, audioId }) => {
    const mesh = createInvisibleMesh(position, rotationY);
    scene.add(mesh);
    setupProximityAudio(mesh, audioId);
  });
}

function createInvisibleMesh(position, rotationY) {
  const marcoGeometry = new THREE.BoxGeometry(0.5, 0.2, 0.1);
  const material = new THREE.MeshBasicMaterial({
    color: 0xcccccc,
    transparent: true,
    opacity: 0,
    depthWrite: false,
  });
  const mesh = new THREE.Mesh(marcoGeometry, material);
  mesh.position.set(...position);
  mesh.rotation.y = rotationY;
  return mesh;
}

function setupProximityAudio(mesh, audioId) {
  const sound = new THREE.PositionalAudio(listener);
  const audioElement = document.getElementById(audioId);

  if (!audioElement) {
    console.error(`Audio element with id ${audioId} not found`);
    return;
  }

  sound.setMediaElementSource(audioElement);

  // Configuración de audio posicional
  sound.setRefDistance(1);
  sound.setMaxDistance(3);
  sound.setRolloffFactor(2);
  sound.setDistanceModel('exponential');
  sound.setVolume(0.9);

  mesh.add(sound);
  monitorProximity(mesh, audioElement);  // Pasar el elemento HTML en vez de sound
}

function monitorProximity(mesh, audioElement) {
  const checkDistance = () => {
    const distance = camera.position.distanceTo(mesh.position);
    if (distance < 3 && audioElement.paused) {  // Si está en rango y el audio está pausado
      audioElement.play();
    } else if (distance >= 3 && !audioElement.paused) {  // Fuera de rango, pausar si se está reproduciendo
      audioElement.pause();
    }
  };

  setInterval(checkDistance, 1000);  // Ajusta el intervalo según la frecuencia deseada
}

export { init };
