import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { paintingData } from './paintingData';

export const loadModelObras = (scene, loadingManager) => {
    return new Promise((resolve, reject) => {
        const loader = new GLTFLoader(loadingManager);
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        loader.setDRACOLoader(dracoLoader);

        // Cargar el modelo GLTF
        loader.load(
            "static/models/caminar-obras.glb",
            (gltf) => {
                const cObras = gltf.scene;
                cObras.position.set(-2, 3, 5); // Ajustar la posición del modelo
                cObras.scale.set(1, 1, 1);
                cObras.rotation.y = Math.PI / 2;

                const paintingNodes = {}; // Objeto para almacenar las posiciones de los nodos

                // Recorrer el modelo para aplicar los ajustes
                cObras.traverse((child) => {
                    if (child.isMesh) {
                        // Configurar sombras para nodos de tipo Mesh
                        child.castShadow = true;
                        child.receiveShadow = true;

                        // Si el mesh tiene una textura (el JPG)
                        if (child.material && child.material.map) {
                            // Cambiar a MeshStandardMaterial si no lo es ya
                            if (!(child.material instanceof THREE.MeshStandardMaterial)) {
                                const texture = child.material.map; // Mantener la textura original
                                child.material = new THREE.MeshStandardMaterial({
                                    map: texture,
                                    roughness: 0.5,  // Ajusta según sea necesario
                                    metalness: 0.1,  // Ajusta según sea necesario
                                });
                                child.material.needsUpdate = true;
                            } else {
                                // Si ya es MeshStandardMaterial, ajustar propiedades si es necesario
                                child.material.roughness = 0.5;  // Ajusta según sea necesario
                                child.material.metalness = 0.1;  // Ajusta según sea necesario
                                child.material.needsUpdate = true;
                            }
                        }
                    }

                    // Extraer las posiciones de los nodos p1, p2, ..., p9
                    if (child.name.startsWith('p')) {
                        child.updateMatrixWorld(true); // Actualizar la matriz del mundo antes de obtener la posición
                        const worldPosition = new THREE.Vector3();
                        child.getWorldPosition(worldPosition); // Obtener la posición en el espacio global
                        paintingNodes[child.name] = worldPosition; // Guardar la posición global del nodo
                    }
                });

                // Actualizar las posiciones de las pinturas
                updatePaintingPositions(paintingNodes);

                // Añadir el modelo a la escena
                scene.add(cObras);

                // Resolver la promesa cuando el modelo haya terminado de cargarse
                resolve();
            },
            undefined,
            (error) => {
                console.error("Error al cargar el modelo caminar-Obras.glb", error);
                reject(error); // Rechazar la promesa en caso de error
            }
        );
    });
};

// Actualizar las posiciones de las pinturas con las posiciones de los nodos
const updatePaintingPositions = (paintingNodes) => {
    if (!paintingNodes || Object.keys(paintingNodes).length === 0) {
        console.error("No se encontraron nodos de painting.");
        return;
    }

    Object.keys(paintingNodes).forEach((key) => {
        const nodeIndex = parseInt(key.replace('p', '')) - 1; // Extraer el número del nodo (p1, p2, ...) y convertirlo en índice
        const nodePosition = paintingNodes[key];
        const painting = paintingData[nodeIndex]; // Usamos el índice para obtener la pintura correcta

        if (painting) {
            painting.nodePosition = nodePosition; // Asignar la posición del nodo a la pintura

            // console.log(`Pintura "${painting.info.title}" asociada al nodo ${key} en la posición:`, nodePosition);
        } else {
            console.warn(`No se encontró una pintura para el nodo ${key}.`);
        }
    });
};

export { };
