import * as THREE from "three";
import { scene } from "./script";

const ENV_URL = 'static/images/env2.jpg'; // Path to your environment image

// Function to create and configure the envMap as a skydome or ceiling sphere
const setupEnvMap = (renderer) => {
  if (renderer) {
    const textureLoader = new THREE.TextureLoader();

    // Load the texture and apply it to the inside of a large sphere
    textureLoader.load(ENV_URL, (texture) => {
      const sphereGeometry = new THREE.SphereGeometry(50, 60, 40); // Adjust size as needed

      const sphereMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.BackSide, // Render the texture on the inside of the sphere
      });

      const envSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

      // Optionally scale or position the sphere to adjust size and height
      envSphere.scale.set(1, 0.5, 1); // This makes the sphere flatter, like a ceiling

      // Add the environment sphere to the scene
      scene.add(envSphere);
    });

  } else {
    console.error('Renderer is not defined.');
  }
};

export {
  setupEnvMap
};
