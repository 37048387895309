import * as THREE from 'three';
import gsap from "gsap";

export class JoyStick {
  constructor(options) {

    this.turnSensitivityLeft = options.turnSensitivityLeft || 1;   // bajo sensibilidad a la izquierda para equilibrar
    this.turnSensitivityRight = options.turnSensitivityRight || 2.2; // subir velocidad de giro hacia la derecha

    // Crear el círculo del joystick
    const circle = document.createElement("div");
    circle.id = 'joystick-circle';  // Asignar un ID en lugar de estilos en línea

    const thumb = document.createElement("div");
    thumb.id = 'joystick-thumb';  // Asignar un ID en lugar de estilos en línea

    // Anidar el thumb dentro del círculo
    circle.appendChild(thumb);
    document.body.appendChild(circle);

    this.domElement = thumb;
    this.maxRadius = options.maxRadius || 40;
    this.maxRadiusSquared = this.maxRadius * this.maxRadius;
    this.onMove = options.onMove;
    this.game = options.game;
    this.origin = { left: this.domElement.offsetLeft, top: this.domElement.offsetTop };

    // Ajustar sensibilidad de giro
    this.turnSensitivity = options.turnSensitivity || 1.5;

    // Inicializamos los eventos del joystick
    this.initJoystickEvents();

    // Mostrar el joystick cuando se haga clic en el botón "ingresar"
    const startButton = document.getElementById("ingresar");
    startButton.addEventListener("click", () => this.showJoystick());
  }

  // Inicia los eventos del joystick (mover y soltar)
  initJoystickEvents() {
    if (this.domElement !== undefined) {
      const joystick = this;
      if ("ontouchstart" in window) {
        this.domElement.addEventListener("touchstart", (evt) => joystick.tap(evt));
      } else {
        this.domElement.addEventListener("mousedown", (evt) => joystick.tap(evt));
      }
    }
  }

  showJoystick() {
    gsap.fromTo(
      document.body, // todo el body  no solo joy
      { opacity: 0 },
      { opacity: 1, duration: 1, ease: "power3.out" }
    );
  }

  getMousePosition(evt) {
    let clientX = evt.targetTouches ? evt.targetTouches[0].pageX : evt.clientX;
    let clientY = evt.targetTouches ? evt.targetTouches[0].pageY : evt.clientY;
    return { x: clientX, y: clientY };
  }

  tap(evt) {
    evt = evt || window.event;
    this.offset = this.getMousePosition(evt);
    const joystick = this;
    if ("ontouchstart" in window) {
      document.ontouchmove = (evt) => joystick.move(evt);
      document.ontouchend = (evt) => joystick.up(evt);
    } else {
      document.onmousemove = (evt) => joystick.move(evt);
      document.onmouseup = (evt) => joystick.up(evt);
    }
  }

  move(evt) {
    evt = evt || window.event;
    const mouse = this.getMousePosition(evt);
    let left = mouse.x - this.offset.x;
    let top = mouse.y - this.offset.y;

    const sqMag = left * left + top * top;

    // Ajuste del thumb para que no se salga del fondo del joystick
    if (sqMag > this.maxRadiusSquared) {
      const magnitude = Math.sqrt(sqMag);
      left /= magnitude;
      top /= magnitude;
      left *= this.maxRadius;
      top *= this.maxRadius;
    }

    // Ajustar la posición del thumb dentro del círculo del joystick
    this.domElement.style.top = `${top + this.maxRadius - this.domElement.clientHeight / 2}px`;
    this.domElement.style.left = `${left + this.maxRadius - this.domElement.clientWidth / 2}px`;

    // Movimiento hacia adelante y hacia atrás (eje Y del joystick)
    const forward = -(top - this.origin.top + this.domElement.clientHeight / 2) / this.maxRadius;

    // Movimiento de giro (eje X del joystick)
    let turn = (left - this.origin.left + this.domElement.clientWidth / 2) / this.maxRadius;

 // Ajuste manual de la velocidad de giro según la dirección
        if (turn < 0) {
            turn *= this.turnSensitivityLeft;  // Ajuste personalizado para giro a la izquierda
        } else {
            turn *= this.turnSensitivityRight; // Ajuste personalizado para giro a la derecha
        }

          // Verificación del callback onMove
          if (this.onMove !== undefined) {
            const smoothedForward = THREE.MathUtils.lerp(0, forward, 1); // velocidad de desplazamiento 1 es ok
            const smoothedTurn = THREE.MathUtils.lerp(0, turn, 1); // velocidad de desplazamiento 1 es ok
            this.onMove.call(this.game, smoothedForward, smoothedTurn);
        }
    
  }

  up(evt) {
    if ("ontouchstart" in window) {
      document.ontouchmove = null;
      document.ontouchend = null;
    } else {
      document.onmousemove = null;
      document.onmouseup = null;
    }

    // Reseteamos la posición del joystick visualmente
    this.domElement.style.top = `${this.origin.top}px`;
    this.domElement.style.left = `${this.origin.left}px`;

    // Detener el movimiento inmediatamente
    this.onMove.call(this.game, 0, 0);  // Resetear el movimiento a 0
  }
}
