import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import gsap from "gsap";
import { Capsule } from 'three/examples/jsm/math/Capsule.js';
import { camera, scene } from './script';
import { GUI } from 'dat.gui';


let listener, soundLlave, soundCompleted;
export const modelBoundingBoxes = [];

let llaveNodes = [];
let secretoNode;
let zona1Node;
let corazonNode;
let llaveBoxes = [];
let collectedKeys = 0;
const totalKeys = 4;
const llave = document.getElementById('llave');
let biblioNode;
let flechaDerNode;
let flechaIzqNode;

// Obtener referencia al marcador del progreso
const keyCounter = document.getElementById('key-counter');

// Obtener referencia al mensaje de completado
const completedMessage = document.getElementById('completed-message');

export const loadModelActivos = (scene, loadingManager, camera) => {
    const loader = new GLTFLoader(loadingManager);
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    loader.setDRACOLoader(dracoLoader);

    listener = new THREE.AudioListener();
    camera.add(listener);

    const audioLoader = new THREE.AudioLoader();
    soundLlave = new THREE.Audio(listener);
    audioLoader.load('static/audio/llave.mp3', (buffer) => {
        soundLlave.setBuffer(buffer);
        soundLlave.setVolume(0.4);
    });

    // Cargar sonido de completado
    soundCompleted = new THREE.Audio(listener);
    audioLoader.load('static/audio/complete.mp3', (buffer) => {
        soundCompleted.setBuffer(buffer);
        soundCompleted.setVolume(0.8);
    });

    loader.load(
        "static/models/caminar-activos.glb",
        (gltf) => {
            const cActivos = gltf.scene;
            cActivos.position.set(-2, 3, 5);
            cActivos.scale.set(1, 1, 1);
            cActivos.rotation.y = Math.PI / 2;
            cActivos.updateMatrixWorld(true);

            cActivos.traverse((node) => {
                if (node.isMesh) {
                    node.castShadow = true;
                    node.receiveShadow = true;
                }

                // Identificar las llaves y el nodo secreto
                if (node.isMesh && ['llave1', 'llave2', 'llave3', 'llave4'].includes(node.name)) {
                    llaveNodes.push(node);
                  //  console.log(`Nodo '${node.name}' encontrado:`, node);

                    node.updateMatrixWorld(true);
                    const box = new THREE.Box3().setFromObject(node);
                    llaveBoxes.push(box);
                    modelBoundingBoxes.push({ node, box, collected: false }); // Añadimos el estado "collected"

                    gsap.to(node.rotation, { duration: 2, y: Math.PI * 2, repeat: -1, ease: "none" });

                    // const helper = new THREE.BoxHelper(node, 0xff0000);
                    // scene.add(helper);
                }

                if (node.isMesh && node.name === 'secreto') {
                    secretoNode = node;
                  //  console.log("Nodo 'secreto' encontrado:", secretoNode);

                    secretoNode.updateMatrixWorld(true);
                    const secretoBox = new THREE.Box3().setFromObject(secretoNode);

                    // Añadir un pequeño margen al bounding box para evitar que el jugador atraviese
                    secretoBox.expandByScalar(0.5);  // Ajusta este valor según sea necesario

                    modelBoundingBoxes.push({ node: secretoNode, box: secretoBox });

                    // const helper = new THREE.BoxHelper(secretoNode, 0x99cc00);
                    // scene.add(helper);
                }



                if (node.isMesh && node.name === 'flecha-der') {
                    flechaDerNode = node;
                  //  console.log("Nodo 'flecha-der' encontrado:", flechaDerNode);

                    flechaDerNode.updateMatrixWorld(true);
                    flechaDerNode.position.y = 2.5;

                    gsap.to(node.position, {
                        duration: 0.5,
                        z: node.position.z + 0.2,  // Cambia el valor para ajustar la distancia del movimiento
                        repeat: -1,   // Repetir indefinidamente
                        yoyo: true,   // Revertir el movimiento
                        ease: "power1.inOut" // Suavizado para un movimiento más fluido
                    });

                }

                if (node.isMesh && node.name === 'flecha-izq') {
                    flechaIzqNode = node;
                   // console.log("Nodo 'flecha-izq' encontrado:", flechaIzqNode);

                    flechaIzqNode.updateMatrixWorld(true);
                    flechaIzqNode.position.y = 2.5;

                    gsap.to(node.position, {
                        duration: 0.5,
                        z: node.position.z - 0.2,  // Cambia el valor para ajustar la distancia del movimiento
                        repeat: -1,   // Repetir indefinidamente
                        yoyo: true,   // Revertir el movimiento
                        ease: "power1.inOut" // Suavizado para un movimiento más fluido
                    });


                }

                if (node.isMesh && node.name === 'biblioteca-1') {
                    biblioNode = node;
                    biblioNode.updateMatrixWorld(true);
                   console.log("Nodo 'biblioteca' posicion:", biblioNode.position);

                    // Configuración de los materiales
                    const materials = Array.isArray(node.material) ? node.material : [node.material];
                    materials.forEach((material) => {
                        if (material.map) {
                            material.map.encoding = THREE.sRGBEncoding;
                            material.map.anisotropy = 16;
                            material.map.needsUpdate = true; // Asegura que el encoding se actualice
                        }
                        // Configuraciones adicionales de material
                        material.roughness = 0.9;
                        material.metalness = 0.1;
                        material.envMapIntensity = 0.1;
                        material.emissiveIntensity = 0.05;
                    });
                    
                }



            });

            scene.add(cActivos);
        },
        undefined,
        (error) => {
            console.error("Error al cargar el modelo caminar-activos.gltf", error);
        }
    );
};

const setupSpotLight = (scene, targetNode) => {
    // Crear el SpotLight y configurarlo
    const spot5 = new THREE.SpotLight(0xefefef, 1);
    spot5.position.set(18, 13, 1);
    spot5.angle = 0.70;
    spot5.penumbra = 0.4;
    spot5.distance = 40;

    // Asignar el nodo `biblioteca` como target
    spot5.target = targetNode;
    scene.add(spot5);

    // Helper para visualizar el SpotLight
    // const spotLightHelper = new THREE.SpotLightHelper(spot5);
    // scene.add(spotLightHelper);


    // GUI para ajustar la posición de la luz y sus propiedades
    // const gui = new GUI();
    // const spot5Folder = gui.addFolder('SpotLight 5');
    // spot5Folder.add(spot5.position, 'x', -20, 30).name('Posición X');
    // spot5Folder.add(spot5.position, 'y', 0, 30).name('Posición Y');
    // spot5Folder.add(spot5.position, 'z', -20, 30).name('Posición Z');
    // spot5Folder.add(spot5, 'angle', 0, Math.PI / 2).name('Ángulo');
    // spot5Folder.add(spot5, 'penumbra', 0, 1).name('Penumbra');
    // spot5Folder.add(spot5, 'distance', 0, 50).name('Distancia');
    // spot5Folder.open();

    // Actualiza el helper en cada frame
    // function updateHelpers() {
    //     spotLightHelper.update();
    // }
    // camera.addEventListener('update', updateHelpers);
};

export const updateBoundingBoxes = () => {
    modelBoundingBoxes.forEach(({ node, box }) => {
        node.updateMatrixWorld(true);
        box.setFromObject(node);
    });
};


export const checkCollisions = (playerCollider) => {
    const playerBox = new THREE.Box3();

    // Calcular manualmente el bounding box del playerCollider
    const capsuleCenter = new THREE.Vector3().addVectors(playerCollider.capsule.start, playerCollider.capsule.end).multiplyScalar(0.5);
    const capsuleHeight = playerCollider.capsule.start.distanceTo(playerCollider.capsule.end);
    const capsuleRadius = playerCollider.radius;

    playerBox.setFromCenterAndSize(capsuleCenter, new THREE.Vector3(capsuleRadius * 2, capsuleHeight, capsuleRadius * 2));

    // Verificar colisiones con otros bounding boxes
    modelBoundingBoxes.forEach(({ node, box, collected }, index) => {  // Pasa el índice aquí
        if (box && box.intersectsBox(playerBox)) {
            if (node.name.startsWith('llave') && !collected) {
             //   console.log(`Colisión detectada con: ${node.name}`);
                collectKey(node, index);  // Pasar el índice correctamente a collectKey
            }
            else if (node.name === 'secreto') {
              //  console.log("Colisión detectada con el nodo 'secreto'. Impidiendo el paso.");
                handleSecretCollision(playerCollider);
            }
        }
    });
};


const handleSecretCollision = (playerCollider) => {
    const capsulePosition = playerCollider.capsule.start.clone();

    // Calculamos si el jugador está dentro o fuera del nodo secreto comparando posiciones
    const isPlayerInside = secretoNode.position.z < playerCollider.capsule.start.z;

    // Vector de dirección de rebote, ajustado según si está dentro o fuera
    let collisionDirection;
    if (isPlayerInside) {
        // Si está dentro, el rebote debe empujar hacia afuera
        collisionDirection = new THREE.Vector3().subVectors(capsulePosition, secretoNode.position).normalize();
    } else {
        // Si está fuera, el rebote debe empujar hacia atrás
        collisionDirection = new THREE.Vector3().subVectors(secretoNode.position, capsulePosition).normalize();
    }

    // Aumentamos la fuerza de rebote si el jugador intenta atravesar desde afuera
    const reboundForce = isPlayerInside ? 0.3 : 0.5;

    // Aplicar la fuerza de rebote
    playerCollider.capsule.start.add(collisionDirection.multiplyScalar(reboundForce));
    playerCollider.capsule.end.add(collisionDirection.multiplyScalar(reboundForce));

    // Sincronizar la cámara con el playerCollider
    camera.position.copy(playerCollider.capsule.start);

    // Añadir una pausa breve para evitar colisiones repetidas
    playerCollider.canCollide = false;
    setTimeout(() => {
        playerCollider.canCollide = true;
    }, 300);  // Ajustar el tiempo de pausa si es necesario
};




const collectKey = (node, index) => {
    // Si la llave ya ha sido recogida, no hacer nada
    if (modelBoundingBoxes[index].collected) return;  // Asegúrate de usar el índice aquí

    // Marcar la llave como recogida
    modelBoundingBoxes[index].collected = true;

    // Animar y ocultar la llave recolectada
    gsap.to(node.position, {
        duration: 2, y: node.position.y + 20, onComplete: () => {
            node.visible = false;
        }
    });

    if (!soundLlave.isPlaying) {
        soundLlave.play();
    }

    // Incrementar el contador
    collectedKeys++;
    updateKeyCounter();

    // Verificar si todas las llaves han sido recogidas
    if (collectedKeys === totalKeys) {
        openSecret();
    }
};


const updateKeyCounter = () => {
    const counterText = `${collectedKeys}/${totalKeys}`;

    // Actualizamos el texto del contador sin eliminar la imagen
    keyCounter.firstChild.nodeValue = counterText + ' '; // Espacio para separar el texto de la imagen
};


const openSecret = () => {
    // Mostrar mensaje de completado // agregar gsap fade in out
    completedMessage.style.display = 'block';
    gsap.fromTo(
        completedMessage,
        {
            opacity: 0,
            scale: 0.5,  // Iniciar invisible
        },
        {
            opacity: 1,
            scale: 1, // Escala hasta el tamaño original
            duration: 0.8,
            ease: "elastic.out(1, 0.3)", // Efecto rebote elástico
            delay: 1,    // Espera 1 segundo antes de aparecer
            onComplete: function () {
                // Mantener visible por 3 segundos y luego hacer fade out
                gsap.to(completedMessage, {
                    opacity: 0,  // Hacer fade out
                    scale: 0.5, // Reduce el tamaño al desaparecer
                    duration: 0.4,
                    ease: "power3.in",
                    delay: 3,    // Mantener visible por 3 segundos antes de desaparecer
                });
            },
        }
    );

    // Reproducir sonido de completado
    if (!soundCompleted.isPlaying) {
        soundCompleted.play();
    }

    // Mover el nodo secreto para abrir la puerta
    gsap.to(secretoNode.position, { duration: 5, y: secretoNode.position.y + 30 });

    // Hacer que el keyCounter desaparezca
    gsap.to(keyCounter, {
        opacity: 0,
        scale: 0.5,
        duration: 0.5,
        ease: "power3.in",
        onComplete: () => {
            keyCounter.style.display = 'none';  // Oculta el elemento al final de la animación
        }
    });
};
