import './style.css';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

let emissiveIntensity = 5.0; // Intensidad inicial de emisión
let increasing = true; // Dirección de la animación

export const loadModelZonas = (scene, loadingManager) => {
    return new Promise((resolve, reject) => {
        const loader = new GLTFLoader(loadingManager);
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        loader.setDRACOLoader(dracoLoader);

        // Cargar el modelo GLTF
        loader.load(
            "static/models/caminar-zonas.glb",
            (gltf) => {
                const cZonas = gltf.scene;
                cZonas.position.set(-2, 3, 5); // Ajustar la posición del modelo
                cZonas.scale.set(1, 1, 1);
                cZonas.rotation.y = Math.PI / 2;

                // Recorrer el modelo para aplicar los ajustes
                cZonas.traverse((child) => {
                    if (child.isMesh) {
                        // Configurar sombras para nodos de tipo Mesh
                      //  child.castShadow = true;
                        child.receiveShadow = true;

                        // Ajustes de material para transparencia
                        child.material.transparent = true;  // Activar transparencia
                        child.material.opacity = 0.6;       // Nivel de opacidad 
                    }
                });

                // Añadir el modelo a la escena
                scene.add(cZonas);

                // Resolver la promesa cuando el modelo haya terminado de cargarse
                resolve();
            },
            undefined,
            (error) => {
                console.error("Error al cargar el modelo caminar-Obras.glb", error);
                reject(error); // Rechazar la promesa en caso de error
            }
        );
    });
};


