// light.js
import * as THREE from 'three';
import { GUI } from 'dat.gui';
import 'dat.gui/build/dat.gui.css';

// Lights general
export const createLight = (scene, camera) => {
  //const gui = new GUI();

    // Ambient Light
    const ambientLight = new THREE.AmbientLight(0xefefef, 1.2);  // color, intensity
    scene.add(ambientLight);

    //-------------------------------------------------------------------

    // spot 1
    const spot1 = new THREE.SpotLight(0xefefef, 5); // Color e intensidad
    spot1.position.set(-7, 9, 4); // Posición de la luz
    spot1.angle = Math.PI / 8; //  (más pequeño = más narrow)
    spot1.penumbra = 0.4; // Controla la suavidad en los bordes del haz de luz
    spot1.distance = 15; // Distancia máxima del haz de luz
    scene.add(spot1);
    // target 1
    const target1 = new THREE.Object3D();
    target1.position.set(-11, 4, 3.6);
    scene.add(target1);
    spot1.target = target1;


    // spot 2
    const spot2 = new THREE.SpotLight(0xefefef, 5); // Color e intensidad
    spot2.position.set(-7, 9, -11); // Posición de la luz
    spot2.angle = Math.PI / 8; // (más pequeño = más narrow)
    spot2.penumbra = 0.4; // Controla la suavidad en los bordes del haz de luz
    spot2.distance = 15; // Distancia máxima del haz de luz
    scene.add(spot2);
    // target 2
    const target2 = new THREE.Object3D();
    target2.position.set(-11, 4, -11);
    scene.add(target2);
    spot2.target = target2;

    // spot 3 // obra 6
    const spot3 = new THREE.SpotLight(0xefefef, 5); // Color e intensidad
    spot3.position.set(4, 5.6, 1); // Posición de la luz
    spot3.angle = 0.3; // (más pequeño = más narrow)
    spot3.penumbra = 0.4; // Controla la suavidad en los bordes del haz de luz
    spot3.distance = 14; // Distancia máxima del haz de luz
    scene.add(spot3);

    // target 3
    const target3 = new THREE.Object3D();
    target3.position.set(10.5, 5, 1);
    scene.add(target3);
    spot3.target = target3;

    // spot 4 // obra 7
    const spot4 = new THREE.SpotLight(0xefefef, 5); // Color e intensidad
    spot4.position.set(7, 2.3, -11); // Posición inicial de la luz
    spot4.angle = 0.46; // Ángulo del haz
    spot4.penumbra = 0.4; // Controla la suavidad en los bordes
    spot4.distance = 14; // Distancia máxima del haz
    scene.add(spot4);

    // target 4
    const target4 = new THREE.Object3D();
    target4.position.set(9, 3, -11);  // Posición inicial del target
    scene.add(target4);
    spot4.target = target4;

    // // spot 5 para biblio

    //     // spot 5 // biblio en 13,4,20
    //     const spot5 = new THREE.SpotLight(0xefefef, 5); // Color e intensidad
    //     spot5.position.set(24, 15, 5); // Posición inicial de la luz
    //     spot5.angle = 0.46; // Ángulo del haz
    //     spot5.penumbra = 0.4; // Controla la suavidad en los bordes
    //     spot5.distance = 24; // Distancia máxima del haz
    //     scene.add(spot5);
    
    //     // target 5
    //     const target5 = new THREE.Object3D();
    //     target5.position.set(-7, 4.3, -16.7);  // Posición inicial del target
    //     scene.add(target5);
    //     spot5.target = target5;



    // // Helpers para spot4 y target4
    // const spotLightHelper = new THREE.SpotLightHelper(spot5);
    // scene.add(spotLightHelper);

    // const targetHelper = new THREE.AxesHelper(1);  // Helper para visualizar el target
    // target5.add(targetHelper);

    // // Actualiza los helpers en cada frame
    // function updateHelpers() {
    //     spotLightHelper.update();
    // }
    // camera.addEventListener('update', updateHelpers);  // Asegura que los helpers se actualicen


    // // GUI para modificar la posición del spot4 y del target4
    // const spot5Folder = gui.addFolder('SpotLight 5');
    // spot5Folder.add(spot5.position, 'x', -20, 30).name('Posición X');
    // spot5Folder.add(spot5.position, 'y', 0, 30).name('Posición Y');
    // spot5Folder.add(spot5.position, 'z', -20, 30).name('Posición Z');
    // spot5Folder.add(spot5, 'angle', 0, Math.PI / 2).name('Ángulo');
    // spot5Folder.add(spot5, 'penumbra', 0, 1).name('Penumbra');
    // spot5Folder.add(spot5, 'distance', 0, 50).name('Distancia');
    // spot5Folder.open();

    // const target5Folder = gui.addFolder('Target 5');
    // target5Folder.add(target5.position, 'x', -20, 20).name('Target X');
    // target5Folder.add(target5.position, 'y', 0, 20).name('Target Y');
    // target5Folder.add(target5.position, 'z', -20, 20).name('Target Z');
    // target5Folder.open();


// Directional Light
const directionalLight = new THREE.DirectionalLight(0xefefef, 3.6);
directionalLight.position.set(0, 180, -74); //
directionalLight.castShadow = true;

// Configuración inicial de sombras
directionalLight.shadow.camera.far = 220;
directionalLight.shadow.camera.near = 0.2;
directionalLight.shadow.camera.right = 16;
directionalLight.shadow.camera.left = -16;
directionalLight.shadow.camera.top = 13;
directionalLight.shadow.camera.bottom = -22;
directionalLight.shadow.mapSize.width = 2048; // 1024, 2048 o 4096
directionalLight.shadow.mapSize.height = 2048; // Increased resolution
directionalLight.shadow.radius = 3; // Reduced radius 
directionalLight.shadow.bias = -0.0001; // Slightly less 
scene.add(directionalLight);

//Helpers
// const directionalLightHelper1 = new THREE.DirectionalLightHelper(directionalLight);
// scene.add(directionalLightHelper1);

// GUI para la luz direccional
// const directionalFolder1 = gui.addFolder('Directional Light');
// directionalFolder1.add(directionalLight.position, 'x', -50, 50).name('Posición X');
// directionalFolder1.add(directionalLight.position, 'y', 0, 300).name('Posición Y');
// directionalFolder1.add(directionalLight.position, 'z', -100, 50).name('Posición Z');
// directionalFolder1.add(directionalLight, 'intensity', 0, 5).name('Intensidad');

// // Subcarpeta en la GUI para parámetros de sombras
// const shadowFolder = directionalFolder1.addFolder('Sombras');
// shadowFolder.add(directionalLight.shadow.camera, 'near', 0.1, 300).name('Near').onChange(updateShadowCamera);
// shadowFolder.add(directionalLight.shadow.camera, 'far', 0.1, 500).name('Far').onChange(updateShadowCamera);
// shadowFolder.add(directionalLight.shadow.camera, 'top', -50, 50).name('Top').onChange(updateShadowCamera);
// shadowFolder.add(directionalLight.shadow.camera, 'bottom', -50, 50).name('Bottom').onChange(updateShadowCamera);
// shadowFolder.add(directionalLight.shadow.camera, 'left', -50, 50).name('Left').onChange(updateShadowCamera);
// shadowFolder.add(directionalLight.shadow.camera, 'right', -50, 50).name('Right').onChange(updateShadowCamera);
// shadowFolder.add(directionalLight.shadow, 'radius', 0, 10).name('Radius'); // Radio de suavizado de sombra
// shadowFolder.add(directionalLight.shadow, 'bias', -0.01, 0.01).name('Bias'); // Ajuste de posición de la sombra

// shadowFolder.open();
// directionalFolder1.open();

// //Función para actualizar la cámara de sombras después de cada cambio
// function updateShadowCamera() {
//     directionalLight.shadow.camera.updateProjectionMatrix();
// }

  
   // Directional Light2
   const directionalLight2 = new THREE.DirectionalLight(0xefefef, 1.5);
   directionalLight2.position.set(0, 105, 100);
  scene.add(directionalLight2);



    //   // // Helpers 
    // const directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight2);
    // scene.add(directionalLightHelper);

    // // GUI para la luz direccional
    // const directionalFolder = gui.addFolder('Directional Light2');
    // directionalFolder.add(directionalLight2.position, 'x', -50, 50).name('Posición X');
    // directionalFolder.add(directionalLight2.position, 'y', 0, 300).name('Posición Y');
    // directionalFolder.add(directionalLight2.position, 'z', -50, 100).name('Posición Z');
    // directionalFolder.add(directionalLight2, 'intensity', 0, 5).name('Intensidad');
    // directionalFolder.open();


       // Directional Light3----------------------------------
   const directionalLight3 = new THREE.DirectionalLight(0xefefef, 1.5);
   directionalLight3.position.set(-15, 105, -10);
  scene.add(directionalLight3);

    // // GUI para la luz direccional
    // const directionalFolder3 = gui.addFolder('Directional Light3');
    // directionalFolder3.add(directionalLight3.position, 'x', -50, 50).name('Posición X');
    // directionalFolder3.add(directionalLight3.position, 'y', 0, 300).name('Posición Y');
    // directionalFolder3.add(directionalLight3.position, 'z', -50, 100).name('Posición Z');
    // directionalFolder3.add(directionalLight3, 'intensity', 0, 5).name('Intensidad');
    // directionalFolder3.open();


          // Directional Light3----------------------------------
   const directionalLight4 = new THREE.DirectionalLight(0xefefef, 1.6);
   directionalLight4.position.set(30, 50, -14);
  scene.add(directionalLight4);

    // GUI para la luz direccional
    // const directionalFolder4 = gui.addFolder('Directional Light4');
    // directionalFolder4.add(directionalLight4.position, 'x', -50, 50).name('Posición X');
    // directionalFolder4.add(directionalLight4.position, 'y', 0, 300).name('Posición Y');
    // directionalFolder4.add(directionalLight4.position, 'z', -50, 100).name('Posición Z');
    // directionalFolder4.add(directionalLight4, 'intensity', 0, 5).name('Intensidad');
    // directionalFolder4.open();


};