import './style.css';
import * as THREE from 'three';
import { scene, camera } from './script';  
import gsap from 'gsap';
import { GUI } from 'dat.gui'; 

// Cubo disparador de referencia
const boxGeometry = new THREE.BoxGeometry(0.1, 0.1, 0.1);
const boxMaterial = new THREE.MeshStandardMaterial({
    color: 0x99cc00,
    transparent: true,   // Habilitar la transparencia
    opacity: 0,          // Hacer el objeto completamente invisible
    depthWrite: false,   // No escribir en el buffer de profundidad para evitar conflictos
});
const box = new THREE.Mesh(boxGeometry, boxMaterial);
box.position.set(19, 8, 3);

// Otras configuraciones
box.renderOrder = 1; // Prioridad en el orden de renderizado (opcional)


// // Añadir los meshes al dat.GUI
//   const gui = new GUI();
// const boxFolder = gui.addFolder('box Position');
// boxFolder.add(box.position, 'x', -20, 20).name('X Position');
// boxFolder.add(box.position, 'y', -20, 20).name('Y Position');
// boxFolder.add(box.position, 'z', -30, 30).name('Z Position');
// boxFolder.open();  // Asegúrate de abrir el folder

// Función para mostrar el popup de los libros
export const checkForBookPopup = (camera) => {
    const distanceThreshold = 1.5;
    const distanceToBox = camera.position.distanceTo(box.position);

    if (distanceToBox < distanceThreshold) {
        displayBookInfo();  // Mostrar popup si estás cerca
    } else {
        hideBookInfo();  // Ocultar popup si te alejas
    }
};

// Función para mostrar el popup
const displayBookInfo = () => {
    const popupElement = document.getElementById('books-popup');
    
    gsap.to(popupElement, {
        opacity: 1,
        duration: 0.5,
        display: 'block',
    });
};

// Función para ocultar el popup
const hideBookInfo = () => {
    const popupElement = document.getElementById('books-popup');
    
    gsap.to(popupElement, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
            popupElement.style.display = 'none';
        }
    });
};

export {box}