import * as THREE from 'three';
import gsap from 'gsap';
import './style.css';

// Obtener referencias a los elementos
const progressBar = document.getElementById('progress-bar');
const progressText = document.getElementById('progress-text');
const intro = document.getElementById('intro');
const ingresarBtn = document.getElementById('ingresar');
const preloader = document.getElementById('preloader');
const instruccionesDiv = document.getElementById('instrucciones');
const logoDiv = document.getElementById('logo-intro');
const textoDiv = document.getElementById('texto-intro');

// Crear un nuevo LoadingManager
const PRELOADER = new THREE.LoadingManager();
instruccionesDiv.style.display = 'block';  // Mostrar instrucciones    
gsap.fromTo(instruccionesDiv, { opacity: 0 }, { opacity: 1, duration:0.1 }); // Animación suave


// Progreso de carga
PRELOADER.onProgress = function (url, loaded, total) {
    const percentage = (loaded / total) * 100;
    progressBar.style.width = `${percentage}%`;
    progressText.textContent = `[ CARGANDO: ${Math.floor(percentage)}% ]`;
};

// Cuando la carga esté completa
PRELOADER.onLoad = function () {
    // Ocultar preloader con animación
  
    gsap.to(preloader, {
        opacity: 0,
        duration: 1,
        
        onComplete: () => {
            preloader.style.display = 'none';
           // logoDiv.style.display = 'block';
           //textoDiv.style.display = 'none';
            ingresarBtn.style.display = 'block';  // Mostrar el botón después de la carga
        },
    });
};

export {
    PRELOADER,
    progressBar,
    intro,
    ingresarBtn,
    preloader,
    progressText,
    instruccionesDiv,
};
