import * as THREE from 'three';

// Variables globales
let listener;
let sound;
export let stepAudio;
let bufferLoaded = false;
let stepAudioEnabled = false;

// Caché para audios de proximidad o bajo demanda
let audioCache = {};

/**
 * Carga de audio diferida (Lazy Loading).
 * @param {string} audioId - ID único del audio.
 * @param {string} src - Ruta al archivo de audio.
 * @returns {Audio} - Elemento de audio cargado.
 */
export function lazyLoadAudio(audioId, src) {
    if (audioCache[audioId]) {
        return audioCache[audioId]; // Si ya está cargado, devolver desde el caché
    }

    const audio = new Audio();
    audio.src = src;
    audio.loop = true;
    audio.load();
    audioCache[audioId] = audio; // Almacenar en caché
    return audio;
}

/**
 * Reproduce audio diferido si no está ya en reproducción.
 * @param {string} audioId - ID único del audio.
 * @param {string} src - Ruta al archivo de audio.
 */
export function playLazyAudio(audioId, src) {
    const audio = lazyLoadAudio(audioId, src);
    if (audio && !audio.playing) {
        audio.play();
        audio.playing = true; // Marcar como reproduciendo
    }
}

/**
 * Configura el sistema de audio inicial.
 * @param {THREE.Camera} camera - Cámara para añadir el listener.
 */
export const setupAudio = (camera) => {
    if (!listener) {
        listener = new THREE.AudioListener();
        camera.add(listener);
    }

    const audioLoader = new THREE.AudioLoader();

    // Sonido de fondo
    if (!sound) {
        sound = new THREE.Audio(listener);
    }

    // Sonido de pasos
    if (!stepAudio) {
        stepAudio = new THREE.Audio(listener);
        audioLoader.load("static/audio/steps.mp3", (buffer) => {
            stepAudio.setBuffer(buffer);
            stepAudio.setLoop(true);
            stepAudio.setVolume(0.4);
            bufferLoaded = true;
        }, undefined, (error) => {
            console.error("Error loading footsteps audio.", error);
        });
    }
};

// Funciones auxiliares para audio
export const getAudioListener = () => listener;

export const stopAudio = () => {
    if (sound) {
        sound.pause();
    }
};

export const enableStepAudio = () => {
    stepAudioEnabled = true;
};

export const playStepAudio = () => {
    if (stepAudioEnabled && stepAudio && bufferLoaded && !stepAudio.isPlaying) {
        stepAudio.play();
    }
};

export const stopStepAudio = () => {
    if (stepAudio && stepAudio.isPlaying) {
        stepAudio.stop();
    }
};
